// // colors and fonts will reside here

// /*  HEX colors */
// $PrimaryDarker:#20306C;
// $Light:#ffffff;
// $Primary:#253570;
// $Accent:#FEE4DF;
// $Secondary:#1c1c37;
// $Border:#E9EFFC;
// $Body:#F3F5F9;
// $Grey:#8492a6;
// $AltBorder:rgba(255,255,255,0.3);
/* RGB */
$Dark: #111;
$Light: rgba(255, 255, 255, 1);
$Divider: rgba(239, 239, 239, 1);
$Accent: rgba(255, 73, 73, 1);


// /* Poppins fonts */

@font-face {
    font-family: 'hype-avantGarde-bold';
    src: url(../fonts/avant-garde.ttf);
  }

  @font-face {
    font-family: 'hype-avantGarde';
    src: url(../fonts/AvantGardeRegular.ttf);
  }

.base-font{
   font-family:'hype-avantGarde-bold', sans-serif !important;
}

.alt-font{
    font-family: 'Maitree', serif;  
}

