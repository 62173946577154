@import 'theme';

.hero-text {
  font-size: 4.5rem;
  letter-spacing: -4px;
  text-align: left;
  @extend .base-font;
}

.hero-intro {
  @extend .alt-font;
  font-size: 18pt;
  color: #7e7e7e;
  margin-bottom: 2.5rem;
  text-align: left;
}


// .hero-contents{

// }

.hero-container {
  padding-right: 25px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 40px;
}

.hero-wrapper {
  height: 90vh;
  background-image: url("../images/circular-pattern.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
}


// scroll indicator

.scroll-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.chevron:before {
  left: 0;
  -webkit-transform: skewY(30deg);
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skewY(-30deg);
  transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
    transform: translateY(4.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    -webkit-transform: translateY(2.28rem);
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    -webkit-transform: translateY(3.12rem);
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(4.8rem) scale(0.5);
    transform: translateY(4.8rem) scale(0.5);
  }
}


//   scroll indicator 2 


.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px $Light solid;
  border-bottom: 2px $Light solid;
}

.arrow:before {
  content: "";
  width: 18px;
  height: 18px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 2px $Dark solid;
  border-bottom: 2px $Dark solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

// work wrapper

.work-wrapper {
  height: auto;
  padding-bottom: 140px;
}

.work-cube-intro {
  @extend .base-font;
}

.column-masonry-wrapper {
  background-color: #fafafa;
  width: 100%;
}

.masonry-columns {
  padding-left: 0;
  padding-right: 0;
}

.box {
  position: relative;
  width: 100%;
  height: 250px;
  background-color: beige;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.rect {
  width: 100%;
  height: 530px;
  background-color: $Dark;
}

.work-wrapper {
  padding-top: 30px;
  padding-bottom: 100px;
}

.brag-intro {
  color: $Light;
  font-size: 3.1rem;
  @extend .base-font;
}

.brag-container {
  display: flex;
  align-items: flex-end;
  padding: 15px 15px 15px 15px;

}

.dark {
  color: $Dark;
}

.arrow-wrapper {
  display: none !important;
}

.overlay {
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: 0.3s;
  padding: 15px;
  justify-content: flex-end;
  flex-direction: column;

  &:hover {
    background-color: #0000005c;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;

    h3 {
      color: $Light;
      display: block;
    }

    .arrow-wrapper {
      display: block !important;
    }
  }

  h3 {
    color: $Light;
    display: none;
    transition: 0.3s;
  }

}


.rect {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.phone {
  width: 260px;
  position: absolute;
  right: 10px;
}

.phone-alt {
  width: 260px;
  position: absolute;
  right: 200px;
}

.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

.floating-alt {
  animation-name: floating-alt;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% {

    transform: translatey(0px);
  }

  50% {

    transform: translatey(-20px);
  }

  100% {

    transform: translatey(0px);
  }
}

@keyframes floating-alt {
  0% {

    transform: translatey(0px);
  }

  50% {
    transform: matrix3d(0.984804, -0.173683, 0, 0, 0.173683, 0.984804, 0, 0, 0, 0, 1, 0, 0, 0.0127376, 0, 1);

  }

  100% {

    transform: translatey(0px);
  }
}


.work-item-wrapper {
  width: 100%;
  background-color: burlywood;
  height: 500px;
  transition: all .5s ease-in-out;
}

.work-item-wrapper:hover {
  transform: scale(0.9);
}

.clients-wrapper {
  background-color: $Dark;
  padding-top: 100px;
  padding-bottom: 100px;

}

.work-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.work-title {
  position: absolute;
  padding-left: 30px;
  padding-top: 30px;

  h1 {
    @extend .base-font;
    color: white;
    margin-bottom: 0;
  }

  p {
    @extend .alt-font;
    color: white;
    font-size: 0.875rem;
  }
}

.dark-title {

  h1,
  p {
    color: $Dark;
  }
}

.centered-title {
  @extend .base-font;
  text-align: center;
  margin-bottom:0;

}

.centered-desc {
  @extend .alt-font;
  text-align: center;
  font-size:0.875rem;
  margin-top:0;
  padding-left:40px;
  padding-right:40px;
  padding-left: 30%;
  padding-right: 30%;
}

.do-icon{
  max-width:50px;
}

.do-title{
  @extend .base-font;
  font-size:1.1rem;
}

.do-desc{
  @extend .alt-font;
  font-size:0.875rem;
}

.do-tile-wrapper{
  background-color:#f1f1f1f1;
  padding:20px 15px 15px 15px;
}

.do-icon-wrapper{
  display:flex;

}

.do-tile-wrapper{
  height:140px;
}

.what-we-do-wrapper{
  padding-bottom:100px;
}

.logo-grid-img{
  width:230px;
}



.logo-grid{
  .row{
    text-align:center;
  }
}

.efforts-wrapper{
  padding-bottom:100px;
}

.clients-wrapper{
  text-align: center;
  h1,p{
    color:White;
  }
  span{
    color:white;
    @extend .base-font;

  }

}

.wefarm-logo{
  width:190px;
}

.contact-wrapper{
  padding-top:80px;
}

// forms

.brief-form{
 label{
  @extend .base-font;
 }
 margin-bottom:30px;
 &[input="text"]{
   font-family: Sans-serif;
 }
 textarea{
  font-family: Sans-serif;
}
 

}

input[type="text"]
{
  font-family:Sans-serif;
  height:70px;
}

.brief-btn{
  @extend .base-font;
  background-color:$Dark;
  width:100%;
  border:none;
  padding-top:10px;
  padding-bottom:10px;
  margin-top:10px;
  height:70px;
}

.hands-img{
    height: 347px;
    margin-top: 58px;
}

.hands-wrapper{
  height:350px;
  display:flex;
  flex-direction:row;
  justify-content: center;
  overflow: hidden;
}

// footer

.footer-wrapper{
  background-color:$Dark;
  height:390px;
  padding-top:70px;
}

.light-title{
  color:$Light;
}

.last-footer-wrapper{
  background-color: #191919;;
  border-top:solid 1px #333333;
  p{
    margin-bottom:0;
    @extend .alt-font;
    font-size:0.875rem;
  }
  padding-top:15px;
  padding-bottom:0px;
  color:$Light;

}

.HypeLoader{
  margin-top: 0;
  width:20%;
}

.hype-loader-wrapper{
  text-align:center;
}

.social-media-icons{
  list-style-type: none;
  display:flex;
}

.last-footer-contents{
  display:flex;
  justify-content: space-between;
}

.social-media-icon{
  margin-right:20px;
}

// media querries

/* Portrait iphone 6*/
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

    .phone, .phone-alt{
      display:none;
    }

    .centered-desc{
      padding-right:15px;
      padding-left:15px;
    }

    .do-icon-wrapper{
      display:flex;
      justify-content:center;
      margin-bottom:5px;
    }

    .do-desc{
      text-align:center;
    }

    .do-tile-wrapper{
      height:auto;
    }

    .what-we-offer-row{
      padding-left:15px;
      padding-right:15px;
    }

    .hero-text{
      text-align: center;
      font-size: 4rem;
    }

    .hero-intro{
      text-align:center;
      font-size: 1.3rem;
    }
    .work-title p{
      font-size: 1rem;
    }
}

// contacts
.contacts-list{
  margin-top:50px;
  list-style-type: none;
  color:white;
  padding-left:0;
  text-align:center;
  li{
    @extend .alt-font;
  }
}

.bolded-text{
  font-weight:600;
}
