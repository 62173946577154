@import 'theme';

.about-intro-wrapper{
    background-color:$Dark;
    widows: 100%;
    height: 600px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:-100px;
}

.about-intro-text{
    @extend .base-font;
    color:$Light;   
    font-size:4rem;
    
}