@import 'theme';

.navbar-brand{
    height:96px;
}

.navbar-wrapper{
    padding-left:20px;
    padding-right:20px;
}

.navbar-links{
    list-style: none;
    display:flex;
    margin-top:23px;
    @extend .base-font;
    .navbar-link{
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 11px;
        padding-bottom: 8px;
        border: .25px 0px .25px .25px solid #FFF;
        border-top:.25px solid #FFF;
        border-right:0 solid #FFF;
        border-left:.25px solid #FFF;
        border-bottom:.25px solid #FFF;
    }
    .lets-talk-link{
        background-color:#131313;
        color:white;
        border:none;
       a{
           color:white;
           &:hover{
               text-decoration: none;
           }
       } 
    }
    }

.navbar-wrapper{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.navbar-links-light{
    list-style: none;
    display:flex;
    margin-top:23px;
    @extend .base-font;
    .navbar-link{
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 11px;
        padding-bottom: 8px;
        border: .25px 0px .25px .25px solid #FFF;
        border-top:.25px solid #FFF;
        border-right:0 solid #FFF;
        border-left:.25px solid #FFF;
        border-bottom:.25px solid #FFF;
        color:white;
    }
    .lets-talk-link{
        background-color:$Light;
        color:$Dark;
        border:none;
    }
    }

    .home-navbar{
        border-top:10px solid $Dark;
    }

    // media query

    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait) { 
        .lets-talk-link{
            padding-right:30px;
            padding-left:30px;
        }
        
    }